@font-face {
  font-weight: 300;
  font-family: "wheelie-font";
  src: url("../../font/ploni-regular-aaa.ttf") format("truetype");
}

@font-face {
  font-weight: 800;
  font-family: "wheelie-font";
  src: url("../../font/ploni-bold-aaa.ttf") format("truetype");
}

@font-face {
  font-weight: 500;
  font-family: "wheelie-font";
  src: url("../../font/ploni-medium-aaa.ttf") format("truetype");
}

html, body {
  transition: transform 400ms ease 0ms;
  font-family: "wheelie-font", serif !important;
}

.hidden{
  display: none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  text-align: right;
  -moz-appearance:textfield; /* Firefox */
}

#form_ccredit{
  .col-form-label{
    margin-left: 10px;
  }
}

html[data-theme="dark"] {
  .react-loading-skeleton{
    --base-color: #1e1e2d;
    --highlight-color: #353549;
  }
}

html[data-theme="light"] {
  .react-loading-skeleton{
    --base-color: #fefeff;
    --highlight-color: #d8d8d8;
  }
}